import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";
import { useEventListener } from "../../../../hooks/UseEventListener";
import { arrows } from "../../../../shared/icons";
import styles from "./CustomSelect.module.scss";

const CustomSelect = (props): ReactElement => {
    const [selectedOption, setSelectedOption] = useState<object>(props.options[0]);
    const [optionsVisible, setOptionsVisibility] = useState<boolean>(false);
    const customSelect = useRef<HTMLDivElement>(null as any);

    const handleSelection = (option: object): void => {
        setSelectedOption(option);
        setOptionsVisibility(false);
        props.handleChange(option);
    };

    const handleClickOutside = (e: PointerEvent): void => {
        if (
            customSelect?.current &&
            customSelect?.current.contains(e.target as Node)
        )
            return;

        setOptionsVisibility(false);
    };

    // useEffect(() =>
    // {
    //     // if (!props.options) return;
    //     // const selected = props.options.filter(x => x.selected)[0];
    //     // if (selected) setSelectedOption(selected.label);
    // }, [props.options]);

    useEffect(() => {
        if (props.options.length > 1) return;
        if (props.options.length === 1) handleSelection(props.options[0])
    }, [])

    useEventListener("pointerdown", handleClickOutside);

    const handleOptionVisible = (option: boolean) => {
        if (props.options.length > 1)
            setOptionsVisibility(option)
    }

    const selected = props.options.find(o => o.value === props.value);

    function getLabel() {
        let label = "";
        if (selected){
            return selected.label || selected.name
        } else {
            return selectedOption.name
        }
    }

    return (
        <div ref={customSelect} className={`${styles.customSelect} ${props.classes.errors ? props.classes.errors : ""}`}>
            <button
                type="button"
                className={`${styles.customSelectToggler} ${props.classes.container}`}
                onClick={() => handleOptionVisible(!optionsVisible)}
            >
                {getLabel()}
                {props.options.length != 1 && <span className={`${optionsVisible ? styles.toggled : ""} ${props.classes.arrow}`}>
                    {arrows.down}
                </span>}
            </button>

            {optionsVisible && (
                <ul className={`${styles.customSelectOptions} ${props.classes.optionsContainer}`}>

                    {props.options.map((option: object, i: number) => (option.value) && (
                        <li key={i}>
                            <button
                                type="button"
                                onClick={() => handleSelection(option)}
                                className={props.classes.option}
                            >
                                <span>{option.name}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

CustomSelect.defaultProps = {
    classes: {
        container: "",
        optionsContainer: "",
        option: "",
        arrow: ""
    }
}

export default CustomSelect;
