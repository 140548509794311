import { config } from "../../config";
import axios, { AxiosInstance } from "axios";

export interface ICreateAgreementData 
{
    id: number, 
    groupId: number, 
    itemId: number, 
    startDate: Date,
    businessUnitCode: string
}

const instance = axios.create();
instance.defaults.baseURL = config.apiUrl;
instance.interceptors.request.use(function (reqConfig) {
    const token = config.apiKey;
    const bearerToken = "Bearer " + token;
    reqConfig.headers.Authorization =  bearerToken;
    return reqConfig;
});

class DashboardApiService {
    private static readonly axiosInstance: AxiosInstance = instance;

    public static async get(url: string, params?: any) {
        return await this.axiosInstance.get(`/${url}`, { params });
    };

    public static async post(url: string, body: any) {
        return await this.axiosInstance.post(`/${url}`, body);
    };

    public static async put(url: string, body: any) {
        return await this.axiosInstance.put(`/${url}`, body);
    };

    public static async getAgreementData(agreementData: ICreateAgreementData): Promise<any> {
        const agreement = await this.axiosInstance.post("members/agreementData", agreementData);

        return agreement.data;
    };

    public static async submitForm(form: any): Promise<any> {
        return await this.axiosInstance.post("forms", form);
    };

    public static setRequestHeaders(name: string, value: string, protectedRoutes: string[]): void {
        this.axiosInstance.interceptors.request.use(
            (request: any) => {
                if (protectedRoutes.some(route => route === request.url)) {
                    request.headers[name] = value;
                }

                return request;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );
    };
};

export default DashboardApiService;
