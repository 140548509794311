import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "gatsby";
import styles from "./membership.module.scss";
import { IData, MembershipProps } from "./MembershipsInterface";


const Membership = (props: PropsWithChildren<MembershipProps>): ReactElement => {
    const { membershipData, selectedLocation } = props;

    return (
        <>
        { membershipData && (
            <div className={`${styles.wrapper} mainBox px-3`}>
                <h2 className={styles.sectionTitle}>Membership Options</h2>

                {membershipData.map((membership: IData) => (
                        <div className={styles.card} key={membership.title}>
                            <div className={`${styles.body} px-2`}>
                                <h4>{membership.title}</h4>
                                <p>{membership.subtitle}</p>

                                <ul>
                                    {membership.details.map((x, i: number) => (
                                        <li key={i}>{x}</li>
                                    ))}
                                </ul>
                            </div>

                            <div className={`${styles.footer} px-1`}>
                                <p className={styles.priceTitle}>{membership.priceLabel ? membership.priceLabel : 'MONTH-TO-MONTH' }</p>
                                <h4>${membership.price}</h4>
                                <Link to="/members/create" state={{ membership, selectedLocation }}>
                                    <button className={styles.button}>JOIN NOW</button>
                                </Link>
                            </div>
                        </div>
                ))}
            </div>
        )}
        </>
    );
};

export default Membership;
