import React, { PropsWithChildren, ReactElement, useState } from "react";
import styles from "./Hero.module.scss";
import useIsBrowser from "../../../hooks/useIsBrowser";
import useWindowWidth, {
    heroDesktopMinWidth,
} from "../../../hooks/useWindowWidth";
import CustomSelect from "../../Account_create_page/components/CustomSelect";

interface HeroProps {
    data: any;
    locations: [];
    selectedLocation: Object;
    handleLocationChange: () => void;
}

const Hero = (props: PropsWithChildren<HeroProps>): ReactElement => {
    const { image, title, description } = props.data;

    const { locations, handleLocationChange } = props;

    const { isBrowser, key } = useIsBrowser();

    if (!isBrowser) return null;

    return (
        <header className={styles.heroContainer} key={key}>
            {image && (
                <img
                    src={image.url}
                    className={styles.imageContainer}
                    alt={image?.alt}
                />
            )}
            <div className={styles.informationWrapper}>
                {title && <h1>{title[0]?.text}</h1>}
                {description && <p>{description[0]?.text}</p>}
                <div className={styles.locationSelect}>
                    {props.selectedLocation && (
                        <CustomSelect
                            options={locations}
                            value={props.selectedLocation.value}
                            handleChange={handleLocationChange}
                        />
                    )}
                </div>
            </div>
        </header>
    );
};

export default Hero;
