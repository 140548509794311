import { useState, useEffect } from "react";

const useIsBrowser = () => {
  const [isBrowser, setIsBrowser] = useState(false);
  const key = isBrowser ? "browser" : "server";

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return { isBrowser, key };
};

export default useIsBrowser;