import React, { useState, useEffect, useRef } from "react";
import Helmet from "../../components/Helmet";
import Membership from "../../components/Widgets/Membership"
import Hero from "./Hero";
import PrismicService from "../../services/PrismicService";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import api from "./../../services/DashboardApiService"
import styles from "./JoinUs.module.scss";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";


const JoinUsPage = (props : any) => {
    const [pageData, setPageData] = useState(null as any);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const background = isDesktop ? pageData?.hero_image : pageData?.hero_image_mobile;

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const getPrismicData = async () => {
            const prismicService = await PrismicService.getInstance();
            const joinUsPageData = (await prismicService.getDocuments("join_us_page")).results[0].data;
            setPageData(joinUsPageData);
        };

        const responseApi = async () => {
            const apiData = await api.get("agreements");
            const locations = apiData.data.response

            if (locations.length > 1) {
                locations.unshift({
                    agreements: null,
                    businessUnitCode: null,
                    description: "",
                    name: "Select a club",
                    label: "Select a club"
                });
            }

            setLocations(locations);
            const locationQP = new URLSearchParams(props.location.search).get("location");
            const presetLocation = locations.find(x => x.businessUnitCode === +locationQP) || locations[0];
            setSelectedLocation(presetLocation);
        }

        responseApi();
        getPrismicData();
    }, []);

    useEffect(() => {
        scrollRef?.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }, [selectedLocation]);

    const handleLocationChange = (location) => {
        const locationId = Number(location.businessUnitCode);
        setSelectedLocation(locations.find(l => l.businessUnitCode === locationId));
        if (history.pushState) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("location", location.businessUnitCode);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.pushState({path: newurl}, '', newurl);
        }
    };

    return (
        <>
            <Helmet
                title={getSeoTitle(pageData, "Join us")}
                description={getSeoDescription(pageData, "Join us page")}
                keywords={getSeoKeywords(pageData, "")}
            />
            <Hero
                data={{ image: background, title: pageData?.hero_title, description: pageData?.hero_description }}
                locations={locations.map((location) => {
                    location.value = location.businessUnitCode
                    return location
                })}
                selectedLocation={selectedLocation}
                handleLocationChange={handleLocationChange}
            />
            {selectedLocation &&
                <div className={styles.membershipWrapper} ref={scrollRef}>
                    <Membership
                        selectedLocation={selectedLocation}
                        membershipData={selectedLocation.agreements}
                    />
                </div>
            }
        </>
    )
};

export default JoinUsPage;